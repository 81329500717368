import React from 'react';
import styled from 'styled-components';
import sampleImage from '../assets/sample-image2.png';

const SectionContainer = styled.section`
  display: flex;
  align-items: center;
  padding: 50px 20px;
  background: #f4f4f9;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 30px 10px;
  }
`;

const ImageWrapper = styled.div`
  flex: 1;
  padding: 10px;

  @media (max-width: 768px) {
    padding: 5px;
  }
`;

const TextWrapper = styled.div`
  flex: 1;
  padding: 10px;

  @media (max-width: 768px) {
    padding: 5px;
    text-align: center;
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  border-radius: 10px;

  @media (max-width: 768px) {
    border-radius: 5px;
  }
`;

const BoldGradientText = styled.h2`
  font-size: 2.5rem;
  background: linear-gradient(135deg, #000 5%, #5151E5 90%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const Description = styled.p`
  font-size: 1.25rem;
  color: #333;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const ImageTextSectionReversed = () => (
  <SectionContainer id="image-text-section-reversed">
    <ImageWrapper>
      <Image src={sampleImage} alt="Sample" />
    </ImageWrapper>
    <TextWrapper>
      <BoldGradientText>Deeper Than Rap Music</BoldGradientText>
      <Description>
        Independent artists usually lack the legal resources of a major label. We provide a platform that enables artists to understand and negotiate their contracts effectively. We make sure you're always in the know, making smart moves, and keeping your creative rights locked down.
      </Description>
    </TextWrapper>
  </SectionContainer>
);

export default ImageTextSectionReversed;

