import React from 'react';
import styled from 'styled-components';
import sampleVideo from '../assets/sample-video.mp4'; // Ensure the correct path to your video
import videoThumbnail from '../assets/sample-video-thumbnail.png'; // Add your video thumbnail image

const SectionContainer = styled.section`
  display: flex;
  align-items: center;
  padding: 50px 20px;
  background: #f4f4f9;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 30px 10px;
  }
`;

const VideoWrapper = styled.div`
  flex: 1;
  padding: 10px;

  @media (max-width: 768px) {
    padding: 5px;
  }
`;

const TextWrapper = styled.div`
  flex: 1;
  padding: 10px;

  @media (max-width: 768px) {
    padding: 5px;
    text-align: center;
  }
`;

const Video = styled.video`
  width: 100%;
  height: auto;
  border-radius: 10px;

  @media (max-width: 768px) {
    border-radius: 5px;
  }
`;

const BoldGradientText = styled.h2`
  font-size: 2.5rem;
  background: linear-gradient(135deg, #000 10%, #5151E5 90%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const Description = styled.p`
  font-size: 1.25rem;
  color: #333;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const ImageTextSection = () => (
  <SectionContainer id="image-text-section">
    <VideoWrapper>
      <Video controls playsInline poster={videoThumbnail}>
        <source src={sampleVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </Video>
    </VideoWrapper>
    <TextWrapper>
      <BoldGradientText>Revolutionizing Hip Hop Contracts</BoldGradientText>
      <Description>
        SMARTRACTX has no competition when it comes to insights and A.I. tools for independent hip-hop artists, transforming the way contracts are understood and handled.
      </Description>
    </TextWrapper>
  </SectionContainer>
);

export default ImageTextSection;



