import React, { useState, useRef, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import axios from 'axios';
import { FaPaperclip, FaFile, FaFilePdf, FaFileWord, FaFileAlt, FaTimes, } from 'react-icons/fa';
import { FaRegPaperPlane } from "react-icons/fa6";
import ReactMarkdown from 'react-markdown';

// Glowing text animation for loading indicator
const glowEffect = keyframes`
  0%, 100% { 
    color: #2c3e50;
    text-shadow: none;
  }
  50% { 
    color: #3498db;
    text-shadow: 0 0 10px #3498db, 0 0 20px #3498db;
  }
`;

const LoadingContainer = styled.div`
  display: inline-block;
  padding: 15px 20px;
  background-color: #fffff;
  border-radius: 20px;
  align-self: center;
  margin: 10px 0;
  text-align: center;

  @media (max-width: 768px) {
    padding: 12px 16px;
  }
`;

const AnalyzingText = styled.div`
  color: #2c3e50;
  font-size: 14px;
  margin-bottom: 8px;
  text-align: center;
`;

const GlowingLetterContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 2px;
  font-weight: bold;
  font-size: 16px;
`;

const GlowingLetter = styled.span`
  animation: ${glowEffect} 1.2s ease-in-out infinite;
  animation-delay: ${props => props.delay}s;
`;

const LoadingIndicator = () => {
  const letters = "SMARTRACTX".split("");
  
  return (
    <LoadingContainer>
      <AnalyzingText>analyzing....</AnalyzingText>
      <GlowingLetterContainer>
        {letters.map((letter, index) => (
          <GlowingLetter 
            key={index}
            delay={(index * 0.1)}
          >
            {letter}
          </GlowingLetter>
        ))}
      </GlowingLetterContainer>
    </LoadingContainer>
  );
};

const PageContainer = styled.div`
  background: linear-gradient(135deg, #000000, #161616, #262626, #303030);
  min-height: 100vh;
  min-height: -webkit-fill-available; // Add this
  height: 100vh;
  padding: 50px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative; // Change from fixed
  width: 100%;
  left: 0;
  top: 0;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 0;
    margin: 0;
    position: fixed; // Keep fixed only for mobile
    overflow: hidden;
  }
`;

const AnalyzerContainer = styled.div` 
  background-color: transparent;
  border-radius: 5px;
  box-shadow: 
    0 4px 6px rgba(41, 121, 255, 0.2),
    0 4px 12px rgba(0, 157, 255, 0.2),
    0 4px 10px rgba(0, 89, 255, 0.2);
  width: 100%;
  max-width: 1200px;
  height: 75vh;
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  position: relative;

  @media (max-width: 768px) {
    height: calc(100vh - 200px); // Adjust this value
    margin-top: 80px;
    border-radius: 0;
    max-width: 95%;
    width: 100%;
    position: absolute;
    top: 0;
    overflow: hidden; // Add this
  }
`;

const ChatArea = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  -webkit-overflow-scrolling: touch;
  margin-bottom: 80px; // Add space for input area

  @media (max-width: 768px) {
    padding: 15px;
    height: 100%;
    overflow-y: scroll; // Change to scroll
    -webkit-overflow-scrolling: touch;
  }

  /* Add smooth scrolling for iOS */
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
`;

const Message = styled.div`
  display: inline-block;
  margin-bottom: 15px;
  padding: 10px 15px;
  border-radius: 20px;
  max-width: 70%;
  word-wrap: break-word;
  font-weight: normal;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', sans-serif;
  ${props => props.isUser ? `
    align-self: flex-end;
    background-color: #3498db;
    color: #f1f1f1;
    display: flex;
    align-items: center;
    ${props.isPreview ? `
      background-color: #34495e;
      padding: 8px 12px;
    ` : ''}
  ` : `
    align-self: flex-start;
    background: linear-gradient(135deg, #000000, #1A1A1A, #333333);
    color: white;
  `}

  @media (max-width: 768px) {
    max-width: 85%;
    padding: 8px 12px;
    font-size: 14px;
  }
`;

const FileIndicator = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;

  @media (max-width: 768px) {
    gap: 6px;
    font-size: 13px;
  }
`;

const FileIcon = styled.span`
  display: flex;
  align-items: center;
  color: inherit;
  font-size: 18px;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const FileName = styled.span`
  color: inherit;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (max-width: 768px) {
    max-width: 150px;
  }
`;

const CancelIcon = styled(FaTimes)`
  margin-left: 8px;
  cursor: pointer;
  font-size: 14px;
  opacity: 0.8;
  
  &:hover {
    opacity: 1;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    padding: 4px;
  }
`;

const InputArea = styled.div`
  display: flex;
  padding: 20px;
  background-color: transparent;
  border-top: 0px solid #bdc3c7;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  z-index: 10; // Add this

  @media (max-width: 768px) {
    padding: 10px;
    gap: 10px;
    position: fixed;
    bottom: env(safe-area-inset-bottom, 0px); // Add this for iPhone safe area
    background-color: rgba(0, 0, 0, 0.9); // Add background to ensure visibility
    backdrop-filter: blur(10px); // Optional: adds a nice blur effect
  }
`;

const Input = styled.textarea`
  flex-grow: 0;
  padding: 6px 15px;
  border: none;
  border-radius: 60px;
  font-size: 18px;
  line-height: 24px;
  min-height: 30px;
  max-height: 70px;
  resize: none;
  width: 60%;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: Roboto;
  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  overflow: hidden;
  position: relative;

  @media (max-width: 768px) {
    min-height: 25px;
    padding: 4px 8px;
    width: calc(100% - 100px); // Adjust width for mobile
    position: relative;
    z-index: 11;
    -webkit-appearance: none;
    border-radius: 20px;
    background: white;
  }
`;

const Button = styled.button`
  background-color: transparent;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: rgba(0, 89, 255, 0.2);
  }

  svg {
    width: 18px; // Set explicit width
    height: 18px; // Set explicit height
  }

  @media (max-width: 768px) {
    width: 35px;
    height: 35px;
    margin-left: 8px;

    svg {
      width: 16px; // Slightly smaller for mobile
      height: 16px; // Slightly smaller for mobile
    }
  }
`;

const FileInput = styled.input`
  display: none;
`;

const FileLabel = styled.label`
  background-color: transparent;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: rgba(0, 89, 255, 0.2);
  }

  svg {
    width: 18px; // Set explicit width
    height: 18px; // Set explicit height
  }

  @media (max-width: 768px) {
    width: 35px;
    height: 35px;
    margin-left: 8px;

    svg {
      width: 16px; // Slightly smaller for mobile
      height: 16px; // Slightly smaller for mobile
    }
  }
`;

const MarkdownMessage = styled(ReactMarkdown)`
  h3 {
    font-weight: bold;
    margin-bottom: 10px;
  }
  p {
    margin-bottom: 10px;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    
    h3 {
      margin-bottom: 8px;
    }
    p {
      margin-bottom: 8px;
    }
  }
`;

const Disclaimer = styled.div`
  color: #fff;
  text-align: center;
  font-size: 14px;
  margin-top: 15px;
  opacity: 0.8;
  max-width: 800px;

  @media (max-width: 768px) {
    font-size: 10px;
    margin-top: 5px;
    padding: 0 15px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: transparent;
    z-index: 999;
  }
`;

const getFileIcon = (fileName) => {
  const extension = fileName.split('.').pop().toLowerCase();
  switch (extension) {
    case 'pdf':
      return <FaFilePdf />;
    case 'doc':
    case 'docx':
      return <FaFileWord />;
    case 'txt':
      return <FaFileAlt />;
    default:
      return <FaFile />;
  }
};

const ContractAnalyzerPage = () => {
  const [messages, setMessages] = useState([{ text: "Please upload a contract to get started.", isUser: false }]);
  const [inputText, setInputText] = useState('');
  const [file, setFile] = useState(null);
  const [hasUploadedContract, setHasUploadedContract] = useState(false);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const chatAreaRef = useRef(null);

  useEffect(() => {
    if (chatAreaRef.current) {
      chatAreaRef.current.scrollTop = chatAreaRef.current.scrollHeight;
    }
  }, [messages]);

  const inputRef = useRef(null);

  const handleInputChange = (e) => {
  setInputText(e.target.value);
  
  // Auto-adjust height with maximum
  if (inputRef.current) {
    inputRef.current.style.height = 'auto';
    inputRef.current.style.height = Math.min(inputRef.current.scrollHeight, 80) + 'px';
  }
};

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setMessages(prev => prev.filter(msg => !msg.isPreview));
      setFile(selectedFile);
      setMessages(prev => [...prev, { 
        text: selectedFile.name,
        isUser: true,
        isFile: true,
        fileName: selectedFile.name,
        isPreview: true
      }]);
    }
  };

  const removeFilePreview = () => {
    setFile(null);
    setMessages(prev => prev.filter(msg => !msg.isPreview));
  };

  const handleSubmit = async () => {
    if (inputText.trim() === '' && !file) return;
  
    setMessages(prev => prev.filter(msg => !msg.isPreview));
  
    let newMessage;
    if (file) {
      newMessage = { 
        text: 'Uploaded a file', 
        isUser: true,
        isFile: true,
        fileName: file.name
      };
    } else {
      newMessage = { 
        text: inputText, 
        isUser: true 
      };
    }
  
    setMessages(prev => [...prev, newMessage]);
    setInputText('');
    
    // Reset input height
    const inputElement = document.querySelector('textarea');
    if (inputElement) {
      inputElement.style.height = 'auto';
    }
  
    setIsAnalyzing(true);

    try {
      let response;
      const apiUrl = process.env.REACT_APP_API_URL;
      console.log('API URL being used:', apiUrl); // Add this debug line

      if (file) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('chatHistory', JSON.stringify(messages));
        response = await axios.post(`${process.env.REACT_APP_API_URL}/analyze-contract-file`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
        setFile(null);
        setHasUploadedContract(true);
      } else {
        response = await axios.post(`${process.env.REACT_APP_API_URL}/analyze-contract`, { 
          contractText: inputText,
          chatHistory: messages,
          hasUploadedContract: hasUploadedContract
        });
      }
      
      const aiResponse = response.data.choices[0].message.content;
      setMessages(prev => [...prev, { text: aiResponse, isUser: false }]);
    } catch (error) {
      console.error('Error analyzing contract:', error);
      setMessages(prev => [...prev, { text: 'An error occurred while analyzing the contract. Please try again.', isUser: false }]);
    } finally {
      setIsAnalyzing(false);
    }
  };

  // Add meta tag to prevent zoom on input focus
  useEffect(() => {
    // Add meta tag
    const meta = document.createElement('meta');
    meta.name = 'viewport';
    meta.content = 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no, viewport-fit=cover';
    document.head.appendChild(meta);
  
    // Add event listener for viewport height
    const setVH = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
  
    // Initial call
    setVH();
    
    // Add event listeners
    window.addEventListener('resize', setVH);
    window.addEventListener('orientationchange', setVH);
  
    // Add touch event handler for chat area
    const chatArea = document.querySelector('#chat-area');
    if (chatArea) {
      chatArea.addEventListener('touchstart', function(e) {
        if (e.target.closest('textarea')) return;
        e.stopPropagation();
      }, { passive: true });
    }
  
    // Cleanup function
    return () => {
      document.head.removeChild(meta);
      window.removeEventListener('resize', setVH);
      window.removeEventListener('orientationchange', setVH);
      
      // Clean up touch event listener
      const chatArea = document.querySelector('#chat-area');
      if (chatArea) {
        chatArea.removeEventListener('touchstart', function(e) {
          if (e.target.closest('textarea')) return;
          e.stopPropagation();
        }, { passive: true });
      }
    };
  }, []);

  return (
    <PageContainer>
      <AnalyzerContainer>
       <ChatArea ref={chatAreaRef} id="chat-area">
          {messages.map((message, index) => (
            <Message key={index} isUser={message.isUser} isPreview={message.isPreview}>
              {message.isUser ? (
                message.isFile ? (
                  <FileIndicator>
                    <FileIcon>{getFileIcon(message.fileName)}</FileIcon>
                    <FileName>{message.fileName}</FileName>
                    {message.isPreview && (
                      <CancelIcon onClick={removeFilePreview} />
                    )}
                  </FileIndicator>
                ) : (
                  message.text
                )
              ) : (
                <MarkdownMessage>{message.text}</MarkdownMessage>
              )}
            </Message>
          ))}
          {isAnalyzing && <LoadingIndicator />}
        </ChatArea>
        <InputArea>
        <Input 
          ref={inputRef}
          as="textarea"
          rows="1"
          value={inputText} 
          onChange={handleInputChange} 
          placeholder="Chat with SMARTRACTX"
        />
          <FileInput 
            type="file" 
            id="file" 
            onChange={handleFileChange} 
            accept=".txt,.pdf,.doc,.docx"
          />
          <FileLabel htmlFor="file">
            <FaPaperclip />
          </FileLabel>
          <Button onClick={handleSubmit}>
          <FaRegPaperPlane />
          </Button>
        </InputArea>
      </AnalyzerContainer>
      <Disclaimer>
        AI models can make mistakes. Always verify important information
      </Disclaimer>
    </PageContainer>
  );
};

export default ContractAnalyzerPage;