import React from 'react';
import styled from 'styled-components';
import sampleImage from '../assets/sample-image3.png'; // Ensure the correct path to your image

const SectionContainer = styled.section`
  display: flex;
  align-items: center;
  padding: 50px 20px;
  background: #f4f4f9;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 30px 10px;
  }
`;

const ImageWrapper = styled.div`
  flex: 1;
  padding: 10px;

  @media (max-width: 768px) {
    padding: 5px;
  }
`;

const TextWrapper = styled.div`
  flex: 1;
  padding: 10px;

  @media (max-width: 768px) {
    padding: 5px;
    text-align: center;
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  border-radius: 10px;

  @media (max-width: 768px) {
    border-radius: 5px;
  }
`;

const BoldGradientText = styled.h2`
  font-size: 2.5rem;
  background: linear-gradient(135deg, #000 10%, #5151E5 90%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const Description = styled.p`
  font-size: 1.25rem;
  color: #333;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const ImageTextSection3 = () => (
  <SectionContainer id="image-text-section-3">
    <ImageWrapper>
      <Image src={sampleImage} alt="Sample" />
    </ImageWrapper>
    <TextWrapper>
      <BoldGradientText>Ten Toes Down for the Artists</BoldGradientText>
      <Description>
        SMARTRACTX breaks it down for you – giving artists clear, straight-up info about their contracts and service agreements, cutting through the legal talk so you know exactly what’s up with your rights and obligations.
      </Description>
    </TextWrapper>
  </SectionContainer>
);

export default ImageTextSection3;

