import React, { useState, useEffect  } from 'react';
import styled from 'styled-components';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { FaBars, FaTimes, FaUser, FaCaretDown } from 'react-icons/fa';
import { loginRequest } from '../config/authConfig';

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 50px;
  font-family: 'Orbitron', sans-serif;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  box-sizing: border-box;
  z-index: 1000;
  transition: background-color 0.3s ease;
  background-color: ${props => 
    props.isScrolled 
      ? 'rgba(0, 0, 0, 0.85)' 
      : 'transparent'
  };

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 20px;
    background-color: ${props => 
      props.isScrolled 
        ? 'rgba(0, 0, 0, 0.85)' 
        : 'transparent'
    };
  }
`;

const LogoText = styled.div`
  font-size: 2rem;
  font-weight: bold;
  background: linear-gradient(90deg, #FF7F50, #1E90FF); /* Gradient colors */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-right: 20px;

  @media (max-width: 768px) {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.9), rgba(44, 62, 80, 0.9));
    z-index: 1000;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 80px;
    transition: all 0.3s ease;
  }

  & > a {
    color: #fff;
    margin: 0 15px;
    text-decoration: none;
    font-size: 18px;

    @media (max-width: 768px) {
      margin: 10px 0;
      font-size: 16px;
      font-weight: normal;
    }
  }
`;

const Overlay = styled.div`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  transition: opacity 0.3s ease;
`;

const HamburgerIcon = styled.div`
  display: none;
  font-size: 24px;
  color: white;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1001;
  }
`;

const UserProfileContainer = styled.div`
  position: relative;
  margin-left: 15px;

  @media (max-width: 768px) {
    margin: 15px 0;
  }
`;

const UserProfileButton = styled.button`
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: normal;
`;

const UserProfileDropdown = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  background: linear-gradient(to bottom, #2c3e50, #1a252f);
  border-radius: 4px;
  padding: 10px;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  z-index: 1000;
  min-width: 200px;

  @media (max-width: 768px) {
    position: static;
    background: none;
    padding: 0;
    width: 100%;
  }
`;

const UserProfileItem = styled.div`
  padding: 5px 0;
  color: #fff;
  font-size: 14px;
  font-weight: normal;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const UserProfileLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  display: block;
  padding: 5px 0;
  font-size: 14px;
  font-weight: normal;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const LogoutButton = styled.button`
  background-color: #e74c3c;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
  margin-top: 10px;

  &:hover {
    background-color: #c0392b;
  }

  @media (max-width: 768px) {
    font-size: 16px;
    padding: 10px 20px;
  }
`;

const SignInButton = styled.button`
  background-color: #27ae60;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: 15px;

  &:hover {
    background-color: #2ecc71;
  }

  @media (max-width: 768px) {
    font-size: 16px;
    padding: 10px 20px;
    margin: 10px 0;
  }
`;

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const { instance, accounts } = useMsal();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      // More reliable scroll detection for mobile
      const scrollTop = 
        window.pageYOffset || 
        document.documentElement.scrollTop || 
        document.body.scrollTop || 
        0;
      
      setIsScrolled(scrollTop > 20); // Lower threshold for more responsive detection
    };

    // Add passive listener for better performance
    window.addEventListener('scroll', handleScroll, { passive: true });
    
    // Check initial position
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleProfile = () => {
    setIsProfileOpen(!isProfileOpen);
  };

  const handleLinkClick = () => {
    setIsOpen(false);
    setIsProfileOpen(false);
  };

  const handleAuthenticatedAction = (path) => {
    if (accounts.length > 0) {
      navigate(path);
      handleLinkClick();
    } else {
      instance.loginRedirect(loginRequest)
        .catch((error) => {
          console.error("Login failed:", error);
        });
    }
  };

  const handleSignIn = () => {
    instance.loginRedirect(loginRequest)
      .catch((error) => {
        console.error("Sign in failed:", error);
      });
  };

  const handleLogout = () => {
    setIsOpen(false);
    setIsProfileOpen(false);
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    }).catch((error) => {
      console.error("Logout failed:", error);
    });
  };

  return (
    <>
      <HeaderContainer isScrolled={isScrolled}>
        <LogoText onClick={() => navigate('/')}>SMARTRACTX</LogoText>
        <HamburgerIcon onClick={toggleMenu}>
          {isOpen ? <FaTimes /> : <FaBars />}
        </HamburgerIcon>
        <Nav isOpen={isOpen}>
          <Link to="/" onClick={handleLinkClick} style={{fontWeight: location.pathname === '/' ? 'bold' : 'normal'}}>Home</Link>
          <Link to="/analyze-contract" onClick={() => handleAuthenticatedAction('/analyze-contract')} style={{fontWeight: location.pathname === '/analyze-contract' ? 'bold' : 'normal'}}>SmartChat</Link>
          {accounts.length > 0 ? (
            <UserProfileContainer>
              <UserProfileButton onClick={toggleProfile}>
                <FaUser /> {accounts[0].name} <FaCaretDown />
              </UserProfileButton>
              <UserProfileDropdown isOpen={isProfileOpen}>
                <UserProfileItem>{accounts[0].username}</UserProfileItem>
                <UserProfileLink to="/profile" onClick={handleLinkClick}>View Profile</UserProfileLink>
                <LogoutButton onClick={handleLogout}>Logout</LogoutButton>
              </UserProfileDropdown>
            </UserProfileContainer>
          ) : (
            <SignInButton onClick={handleSignIn}>Sign In</SignInButton>
          )}
        </Nav>
      </HeaderContainer>
      <Overlay isOpen={isOpen} onClick={toggleMenu} />
    </>
  );
};

export default Header;













