import React, { useState } from 'react';
import styled from 'styled-components';
import artistImage from '../assets/independent-artist.png';
import managerImage from '../assets/manager.png';
import labelImage from '../assets/small-label.png';

const SectionContainer = styled.section`
  padding: 50px 20px;
  background-color: #f4f4f9;
  text-align: center;
`;

const PersonaContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`;

const Persona = styled.div`
  flex: 1 1 300px;
  margin: 10px;
  padding: 20px;
  background: ${({ background }) => `url(${background})`} no-repeat center center/cover;
  border-radius: 5px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  color: #fff;
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 0 0 6px rgba(0, 0, 0, 0.3);
  }
`;

const PersonaTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 10px;
`;

const PersonaDescription = styled.p`
  font-size: 1rem;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 5px;
`;

const TargetCustomer = () => {
  const [openPersona, setOpenPersona] = useState(null);

  const togglePersona = (index) => {
    setOpenPersona(openPersona === index ? null : index);
  };

  return (
    <SectionContainer>
      <PersonaContainer>
        <Persona onClick={() => togglePersona(0)} background={artistImage}>
          <PersonaTitle>Independent Artist</PersonaTitle>
          <PersonaDescription isOpen={openPersona === 0}>
            The self made artist looking for tools to help manage their contracts and understand the legal language and complexity of the music business.
          </PersonaDescription>
        </Persona>
        <Persona onClick={() => togglePersona(1)} background={managerImage}>
          <PersonaTitle>Manager</PersonaTitle>
          <PersonaDescription isOpen={openPersona === 1}>
            Managers need a way to handle multiple contracts for their artists, making sure that they get the best deals and understand all terms clearly.
          </PersonaDescription>
        </Persona>
        <Persona onClick={() => togglePersona(2)} background={labelImage}>
          <PersonaTitle>Small Record Label</PersonaTitle>
          <PersonaDescription isOpen={openPersona === 2}>
            Start up record labels require modern contract management solutions to scale their operations and provide clarity to their signed artists.
          </PersonaDescription>
        </Persona>
      </PersonaContainer>
    </SectionContainer>
  );
};

export default TargetCustomer;


