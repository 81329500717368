import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Arial', sans-serif;
    overflow-x: hidden; /* Prevent horizontal scrolling */
    background-color: transparent; /* Add this */
  }

  html {
    max-width: 100vw; /* Ensure content fits within the viewport */
    overflow-x: hidden; /* Prevent horizontal scrolling */
    background-color: transparent; /* Add this */
  }

  #root {
    background-color: transparent; /* Add this */
  }

  a {
    text-decoration: none;
    color: inherit;
  }

/* Mobile fixes */
@media (max-width: 768px) {
  html {
    height: -webkit-fill-available;
    height: 100%;
  }
  
  body {
    height: 100vh;
    height: -webkit-fill-available;
    width: 100%;
    position: fixed;
    overscroll-behavior-y: none;
    -webkit-overflow-scrolling: touch;
  }
  
  #root {
    height: 100%;
    position: fixed;
    width: 100%;
    overflow: hidden;
  }

  input[type="text"],
  textarea {
    font-size: 16px !important;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
    -webkit-appearance: none;
    appearance: none;
    position: relative;
  }
}
`;

export default GlobalStyle;



