import React from 'react';
import styled from 'styled-components';

const FeaturesContainer = styled.section`
  padding: 50px 20px;
  text-align: center;
  background: linear-gradient(135deg, #333 50%, #444 75%);
`;

const FeaturesTitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 40px;
   background: linear-gradient(135deg, #72EDF2 35%, #5151E5 70%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const FeatureGrid = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`;

const Feature = styled.div`
  flex: 1 1 300px;
  margin: 10px;
  padding: 20px;
  background: linear-gradient(135deg, #444 40%, #000 75%);
  border-radius: 5px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
`;

const FeatureTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 10px;
  background: linear-gradient(135deg, #72EDF2 10%, #5151E5 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const FeatureDescription = styled.p`
  font-size: 1rem;
  color: white;
`;

const Features = () => (
  <FeaturesContainer id="features">
    <FeaturesTitle>App Features</FeaturesTitle>
    <FeatureGrid>
      <Feature>
        <FeatureTitle>AI-Driven Analysis</FeatureTitle>
        <FeatureDescription>Automatically identify and break down key terms from contracts.</FeatureDescription>
      </Feature>
      <Feature>
        <FeatureTitle>Contract Clarity</FeatureTitle>
        <FeatureDescription>Turn legal talk into plain language to understand and edit contracts.</FeatureDescription>
      </Feature>
      <Feature>
        <FeatureTitle>Industry Insights</FeatureTitle>
        <FeatureDescription>Access the most recent information and tutorials on the music industry.</FeatureDescription>
      </Feature>
      <Feature>
        <FeatureTitle>Customization</FeatureTitle>
        <FeatureDescription>Tailor the app's Smart Contract features to your specific needs and preferences.</FeatureDescription>
      </Feature>
    </FeatureGrid>
  </FeaturesContainer>
);

export default Features;


