const msalConfig = {
  auth: {
    clientId: 'bb261e3d-9391-4790-a4ba-e0e10495a926',  // Your app's client ID from Azure B2C
    authority: 'https://smartractxapp.b2clogin.com/smartractxapp.onmicrosoft.com/B2C_1_signin_signup', // Your tenant authority and policy
    redirectUri: 'https://smartractx.com', // Make sure this matches your registered URI in Azure B2C
    knownAuthorities: ['smartractxapp.b2clogin.com'], // Ensure you're using your B2C tenant domain
  },
  cache: {
    cacheLocation: 'localStorage', // Use localStorage or sessionStorage depending on your needs
    storeAuthStateInCookie: false, // Set to true if needed for legacy browser compatibility
  },
};

const loginRequest = {
  scopes: ['openid', 'profile', 'email', 'offline_access', 'https://graph.microsoft.com/.default',], // The scopes your app needs
};

export { msalConfig, loginRequest };


  
  
  
  


  