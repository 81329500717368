import React from 'react';
import styled from 'styled-components';

const AboutContainer = styled.section`
  padding: 50px 20px;
  background: linear-gradient(135deg, #1e3c72, #2a5298);
  text-align: center;
  color: white;

  @media (max-width: 768px) {
    padding: 30px 15px;
  }
`;

const AboutTitle = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const AboutDescription = styled.p`
  font-size: 1.25rem;
  max-width: 800px;
  margin: 0 auto;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const AboutUs = () => (
  <AboutContainer id="about">
    <AboutTitle>We Are The Culture</AboutTitle>
    <AboutDescription>
      SMARTRACTX is dedicated to giving independent artists the smarts to handle their business. Our AI-powered app lets you roll through the complexities of recording contracts and service agreements like a boss. With deep analysis and risk assessment tools, all tailored for self-made artists to make informed decisions. Join the movement to put every artist in position, one Smart Contract at a time.
    </AboutDescription>
  </AboutContainer>
);

export default AboutUs;


