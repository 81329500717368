import React from 'react';
import ReactDOM from 'react-dom';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './config/authConfig';
import AppWithAuth from './App';
import GlobalStyle from './styles'; // Global styles

// Initialize MSAL instance
const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <React.StrictMode>
    <link href="https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700&display=swap" rel="stylesheet" />
    <AppWithAuth instance={msalInstance} />
    <GlobalStyle />
  </React.StrictMode>,
  document.getElementById('root')
);













