import React from 'react';
import styled from 'styled-components';
import { FaInstagram, FaFacebook } from 'react-icons/fa';

const FooterContainer = styled.footer`
  padding: 20px 50px;
  background-color: #222;
  color: #fff;
  text-align: center;
`;

const FooterText = styled.p`
  font-size: 1rem;
`;

const SocialIcons = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
`;

const IconLink = styled.a`
  color: #fff;
  font-size: 1.5rem;
  transition: color 0.3s;

  &:hover {
    color: #ff7e5f;
  }
`;

const Footer = () => (
  <FooterContainer>
    <FooterText>&copy; {new Date().getFullYear()} SMARTRACTX. All rights reserved.</FooterText>
    <SocialIcons>
      <IconLink href="https://www.instagram.com/smartractx/" target="_blank" aria-label="Instagram">
        <FaInstagram />
      </IconLink>
      <IconLink href="https://www.facebook.com/profile.php?id=61563351074151" target="_blank" aria-label="Facebook">
        <FaFacebook />
      </IconLink>
    </SocialIcons>
  </FooterContainer>
);

export default Footer;


