import React, { useState } from 'react'; 
import styled from 'styled-components';
import { useMsal } from '@azure/msal-react';
import Footer from '../components/Footer';

const videos = [
  {
    id: 1,
    title: 'Advances and Recoupment',
    category: 'The Basics',
    thumbnail: 'https://sxappstorage.blob.core.windows.net/video-library/Advances and Recoupment.jpg',
    url: 'https://sxappstorage.blob.core.windows.net/video-library/Advances and Recoupment.mp4',
  },
  {
    id: 2,
    title: 'Artist Royalties',
    category: 'The Basics',
    thumbnail: 'https://sxappstorage.blob.core.windows.net/video-library/Artist Royalties.jpg',
    url: 'https://sxappstorage.blob.core.windows.net/video-library/Artist Royalties.mp4',
  },
  {
    id: 3,
    title: 'Collateralization',
    category: 'The Basics',
    thumbnail: 'https://sxappstorage.blob.core.windows.net/video-library/Collateralization.jpg',
    url: 'https://sxappstorage.blob.core.windows.net/video-library/Collateralization.mp4',
  },
  {
    id: 4,
    title: 'Master Recordings',
    category: 'The Basics',
    thumbnail: 'https://sxappstorage.blob.core.windows.net/video-library/Masters.jpg',
    url: 'https://sxappstorage.blob.core.windows.net/video-library/Masters.mp4',
  },
  {
    id: 5,
    title: 'Artist and Label',
    category: 'Contracts',
    thumbnail: 'https://sxappstorage.blob.core.windows.net/video-library/Artist and Label.jpg',
    url: 'https://sxappstorage.blob.core.windows.net/video-library/Artist and Label.mp4',
  },
  {
    id: 6,
    title: 'Publisher and Songwriter',
    category: 'Contracts',
    thumbnail: 'https://sxappstorage.blob.core.windows.net/video-library/Publisher and Songwriter.jpg',
    url: 'https://sxappstorage.blob.core.windows.net/video-library/Publishing and Songwriter.mp4',
  },
  {
    id: 7,
    title: 'Artist Management',
    category: 'Contracts',
    thumbnail: 'https://sxappstorage.blob.core.windows.net/video-library/Artist Management.jpg',
    url: 'https://sxappstorage.blob.core.windows.net/video-library/Artist Management.mp4',
  },
  {
    id: 8,
    title: '360 Deals',
    category: 'Contracts',
    thumbnail: 'https://sxappstorage.blob.core.windows.net/video-library/360 Deals.jpg',
    url: 'https://sxappstorage.blob.core.windows.net/video-library/360 Deals.mp4',
  },
  {
    id: 9,
    title: 'Producer Agreement',
    category: 'Contracts',
    thumbnail: 'https://sxappstorage.blob.core.windows.net/video-library/Producer Agreement.jpg',
    url: 'https://sxappstorage.blob.core.windows.net/video-library/Producer Agreement.mp4',
  },
  {
    id: 10,
    title: 'Artist Investment Agreement',
    category: 'Contracts',
    thumbnail: 'https://sxappstorage.blob.core.windows.net/video-library/Investment Agreement.jpg',
    url: 'https://sxappstorage.blob.core.windows.net/video-library/Investment Agreement.mp4',
  },
];

const VideoLibraryContainer = styled.section`
  padding: 50px 20px;
  background: linear-gradient(135deg, #fafafa, #A0AEC0);
  text-align: center;
  min-height: 100vh;
`;

const Title = styled.h2`
  font-size: 2rem;
  font-weight: bold;
  background: linear-gradient(175deg, #FFA07A, #6c5ce7, #000000);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const Dropdown = styled.select`
  padding: 12px;
  margin-bottom: 40px;
  font-size: 1.2rem;
  background-color: #1E2A38;
  color: #fff;
  border: none;
  border-radius: 5px;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const VideoGrid = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const VideoItem = styled.div`
  flex: 1 1 300px;
  margin: 20px;
  max-width: 400px;

  h3 {
    font-size: 1.2rem;
    background: linear-gradient(175deg, #FFA07A, #6c5ce7, #000000);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 10px;
  }

  video {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }

  @media (max-width: 768px) {
    margin: 20px 0;
  }
`;

const VideoLibrary = () => {
  const [selectedCategory, setSelectedCategory] = useState('All');
  const { accounts } = useMsal();

  const filteredVideos =
    selectedCategory === 'All'
      ? videos
      : videos.filter((video) => video.category === selectedCategory);

  if (accounts.length === 0) {
    return (
      <VideoLibraryContainer>
        <Title>Content Hub</Title>
        <p>Please log in to access the video library.</p>
      </VideoLibraryContainer>
    );
  }

  return (
    <>
      <VideoLibraryContainer>
        <Title>Content Hub</Title>
        <Dropdown
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
        >
          {['All', 'The Basics', 'Contracts'].map((category) => (
            <option key={category} value={category}>
              {category}
            </option>
          ))}
        </Dropdown>
        <VideoGrid>
          {filteredVideos.map((video) => (
            <VideoItem key={video.id}>
              <h3>{video.title}</h3>
              <video controls src={video.url} poster={video.thumbnail} />
            </VideoItem>
          ))}
        </VideoGrid>
      </VideoLibraryContainer>
      
      <Footer />
    </>
  );
};

export default VideoLibrary;












