import React, { useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { Navigate } from 'react-router-dom';
import styled from 'styled-components';
import { loginRequest } from '../config/authConfig';

const PageContainer = styled.div`
  background: linear-gradient(135deg, #1E2A38, #555555); /* Dark gray to blueish gray */
  min-height: 100vh;
  padding: 50px;
`;

const ProfileContainer = styled.div`
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
`;

const ProfileTitle = styled.h1`
  color: #2c3e50;
`;

const ProfileForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const ProfileField = styled.div`
  margin: 10px 0;
`;

const Label = styled.label`
  font-weight: bold;
  margin-bottom: 5px;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Button = styled.button`
  background-color: #2c3e50;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    background-color: #34495e;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  margin-top: 10px;
`;

const SuccessMessage = styled.p`
  color: green;
  margin-top: 10px;
`;

const Profile = () => {
  const { instance, accounts } = useMsal();
  const [isEditing, setIsEditing] = useState(false);
  const [profile, setProfile] = useState({
    displayName: '',
    givenName: '',
    surname: '',
    mail: '',
  });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    const fetchAdditionalUserInfo = async () => {
      if (accounts.length > 0) {
        try {
          const response = await instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0]
          });
          
          const user = accounts[0];
          const idTokenClaims = response.idTokenClaims;
          
          setProfile({
            displayName: idTokenClaims.name || user.name || '',
            givenName: idTokenClaims.given_name || user.given_name || '',
            surname: idTokenClaims.family_name || user.family_name || '',
            mail: idTokenClaims.emails?.[0] || user.username || '',
          });
        } catch (error) {
          console.error("Error fetching user info:", error);
        }
      }
    };

    fetchAdditionalUserInfo();
  }, [accounts, instance]);

  if (accounts.length === 0) {
    return <Navigate to="/" />;
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfile({ ...profile, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);
    
    try {
      await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0]
      });

      // Here you would typically make an API call to update the user's profile
      // using the acquired token. For example:
      // await updateUserProfile(token, profile);

      setIsEditing(false);
      setSuccess("Profile updated successfully!");
    } catch (error) {
      console.error("Error updating profile:", error);
      setError("Failed to update profile. Please try again.");
    }
  };

  return (
    <PageContainer>
      <ProfileContainer>
        <ProfileTitle>User Profile</ProfileTitle>
        {isEditing ? (
          <ProfileForm onSubmit={handleSubmit}>
            <ProfileField>
              <Label htmlFor="displayName">Display Name</Label>
              <Input
                type="text"
                id="displayName"
                name="displayName"
                value={profile.displayName}
                onChange={handleInputChange}
              />
            </ProfileField>
            <ProfileField>
              <Label htmlFor="givenName">First Name</Label>
              <Input
                type="text"
                id="givenName"
                name="givenName"
                value={profile.givenName}
                onChange={handleInputChange}
              />
            </ProfileField>
            <ProfileField>
              <Label htmlFor="surname">Last Name</Label>
              <Input
                type="text"
                id="surname"
                name="surname"
                value={profile.surname}
                onChange={handleInputChange}
              />
            </ProfileField>
            <ProfileField>
              <Label htmlFor="mail">Email</Label>
              <Input
                type="email"
                id="mail"
                name="mail"
                value={profile.mail}
                onChange={handleInputChange}
                readOnly
              />
            </ProfileField>
            <Button type="submit">Save Changes</Button>
            <Button type="button" onClick={() => setIsEditing(false)}>Cancel</Button>
          </ProfileForm>
        ) : (
          <>
            <p><strong>Display Name:</strong> {profile.displayName}</p>
            <p><strong>First Name:</strong> {profile.givenName}</p>
            <p><strong>Last Name:</strong> {profile.surname}</p>
            <p><strong>Email:</strong> {profile.mail}</p>
            <Button onClick={() => setIsEditing(true)}>Edit Profile</Button>
          </>
        )}
        {error && <ErrorMessage>{error}</ErrorMessage>}
        {success && <SuccessMessage>{success}</SuccessMessage>}
      </ProfileContainer>
    </PageContainer>
  );
};

export default Profile;

