import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const HeroContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(135deg, #444 10%, #000 75%);
  color: #fff;
  text-align: center;
  padding: 0 20px;
  

  @media (max-width: 768px) {
    height: auto;
    padding: 40px 20px;
  }
`;

const HeroTitle = styled.h1`
  font-size: 4rem;
  margin-bottom: 20px;
  background: linear-gradient(135deg, #72EDF2 10%, #5151E5 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (max-width: 768px) {
    font-size: 2rem;
    margin: 100px 0 0 0; /* Adjust top margin for mobile */
  }
`;

const HeroSubtitle = styled.p`
  font-size: 1.5rem;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const HeroButton = styled(Link)`
  padding: 15px 30px;
  background: linear-gradient(135deg, #1e3c72, #2a5298);
  color: #fff;
  text-decoration: none;
  font-size: 1.2rem;
  border-radius: 5px;
  

  @media (max-width: 768px) {
    padding: 10px 20px;
    font-size: 1rem;
  }
`;

const Hero = () => (
  <HeroContainer>
    <HeroTitle>Level Up Your Music Grind</HeroTitle>
    <HeroSubtitle>an AI powered app that analyzes and simplifies recording contracts</HeroSubtitle>
    <HeroButton to="/analyze-contract">SmartChat</HeroButton>
  </HeroContainer>
);

export default Hero;



