import React from 'react';
import styled from 'styled-components';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../config/authConfig';
import { Link } from 'react-router-dom';

const CTAContainer = styled.section`
  padding: 50px 20px;
  background: #222;
  color: #fff;
  text-align: center;
`;

const CTATitle = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 20px;
  background: linear-gradient(135deg, #fff 0%, #fff 65%, #fff 75%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const CTASubtitle = styled.p`
  font-size: 1.5rem;
  margin-bottom: 30px;
`;

const CTAButton = styled.button`
  padding: 15px 30px;
  background-color: #fff;
  color: #222;
  text-decoration: none;
  font-size: 1.2rem;
  border-radius: 5px;
  margin: 10px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const CTAButton1 = styled(Link)`
 padding: 15px 30px;
  background-color: #fff;
  color: #222;
  text-decoration: none;
  font-size: 1.2rem;
  border-radius: 5px;
  margin: 10px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const CTA = () => {
  const { instance, accounts } = useMsal();

  const handleSignUp = () => {
    instance.loginRedirect(loginRequest)
      .catch((error) => {
        console.error("Sign up failed:", error);
      });
  };

  return (
    <CTAContainer id="cta">
      <CTATitle>Start Chatting Now</CTATitle>
      <CTASubtitle>Join the evolution of AI powered contract analysis for independent artists.</CTASubtitle>
      {accounts.length === 0 && (
        <CTAButton onClick={handleSignUp}>
          Sign Up
        </CTAButton>
      )}
      <CTAButton1 to="/analyze-contract">SmartChat
      </CTAButton1>
    </CTAContainer>
  );
};

export default CTA;


